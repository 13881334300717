Nav{
    background: rgba(0, 0, 0, 0.45);
    width:max-content;
    display: block;
    padding: 0.7rem 1.7rem;
    z-index: 2;
    position: fixed;
    left: 50%;
    transform: translateX(-50%);
    bottom: 2.5rem;
    display: flex;
    gap: 1.7rem;
    border-radius: 3rem;
    backdrop-filter: blur(15px);
}

Nav a{
    background: transparent;
    
    padding: 0.9rem;
    border-radius: 50%;
    display: flex;
    color: var(--color-light);
    font-size: 1.1rem;
    /* border: 1px thin var(--color-light); */
}

Nav a:hover{
    /* background: rgba(0, 0, 0, 0.3);  */
    background: var(--color-bg);
}

Nav a.active{
    background: var(--color-bg);
    color: var(--color-white);
}

Nav a.active:hover{
    background: rgba(0, 0, 0, 0.77); 
    /* background: var(--color-bg); */
}