/* FIX NAMES AS SOON AS POSSIBLE */

.container.testimonials_container{
    width: 60%;
    padding-bottom: 2rem;
   
}

.swiper-pagination-clickable .swiper-pagination-bullet{

    background: var(--color-primary);

}

.client_avatar{
    width: 7rem;
    aspect-ratio: 1/1;
    overflow: hidden;
    border-radius: 50%;
    margin: 0 auto 1rem;
    border: 0.27rem solid var(--color-primary-variant);
}

.testimonial{
    background: var(--color-bg-variant);
    text-align: center;
    padding: 2rem;
    border-radius: 2rem;
    user-select: none;
}

.client_testimony{
    color: var(--color-white);
    font-weight: 300;
    display: block;
    margin: 0.8rem auto 0;
}

.testimony_button{
    margin-top: 1rem;
}