header{
    height: 100;
    padding-top: 7rem;
    overflow: hidden;
}

.header_container{
    text-align: center;
    height: 100%;
    position: relative;
}

/* cta*/

.cta{
    margin-top: 2.5rem;
    display: flex;
    gap: 1.2rem;
    justify-content: center;
}

/* social*/

.header_socials{
    
    display: flex;
    flex-direction: column;
    align-items: column;
    gap: 0.8rem;
    position: absolute;
    left: 1.7rem;
    bottom: 3rem;
    
    
    
}

.header_socials a{
    backdrop-filter: blur(30px);
    scale: 145%;
    background: rgba(0, 0, 0, 0.45);
    width:max-content;
    padding: 0.17rem 0.5rem;
    border-radius: 3rem;
    border-radius: 50%;   
}

.header_socials a:hover{
    background: rgba(0, 0, 0, 0.77);
}

.header_socials::after{
    content: "";
    width: 3.7px;
    height: 2rem;
    background: var(--color-white);
    backdrop-filter: blur(15px);
}
.header_socials::before{
    content: "";
    width: 3.7px;
    height: 2rem;
    background: var(--color-white);
    backdrop-filter: blur(15px);
}

/*image*/
.me {
    background: linear-gradient(var(--color-primary),transparent);
    width: 27rem; 
    height: 30em; /* make flexable */
    margin: auto; 
    margin-top: 4rem;
    overflow: hidden;
    border-radius: 20rem 20rem 0 0;
    padding: 5rem 1.5rem 1.5rem 1.5rem;
    transition: var(--transition);
    
}

.scroll_down{
    position: absolute;
    right: 3rem;
    bottom: 2rem;
    scale: 270%;
    /* transform: rotate(90deg); */

}



/* tablet */

@media screen and (max-width: 1024px){
    .containter{
        width: var(--container-width-mg);
    }

    .me{
        width: 23rem;
    }

    section{
        margin-top: 6rem;
    }
}

/* phone */

@media screen and (max-width: 600px){
    .containter{
        width: var(--container-width-sm);
    }



    section > h2{
        margin-top: 2rem;
    }
} 

 
